// CommonLayout.js
import React from "react";
import { Container } from "reactstrap";
import "../../common.scss";
import Nav from "../../components/navbar";
const Wrapper = ({ children }) => {
  return (
    <>
      <Nav />
      <div className="main-layout hide-scrollbar">
        <Container>{children}</Container>
      </div>
    </>
  );
};

export default Wrapper;
