import React from "react";
import {
  Card,
  Col,
  Row,
  Descriptions,
  Tag,
  Button,
  Space,
  Divider,
  Typography,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { bookingDetail } from "../utility/commonApi";
import Loader from "../views/common/loader";
import moment from "moment";
import Wrapper from "../views/common/commonWrapper";
import "../common.scss";
import backIcon from "../assets/icons/back.svg";
const { Title, Text } = Typography;

function BookingDetail() {
  const navigate = useNavigate();
  const { bookingId } = useParams(); // Get bookingId from the URL

  // Fetch booking detail using React Query
  const { data, isLoading, isError } = useQuery({
    queryKey: ["bookingDetail", bookingId], // The query key
    queryFn: () => bookingDetail(bookingId), // The fetch function
  });

  const bookingData = data?.data?.result ?? null; // Use optional chaining and fallback to null

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !bookingData) {
    return <div>Failed to load booking details</div>;
  }

  const { userId, bookedSlots, status, bookingId: bookingCode } = bookingData;
  const serviceDetails = bookedSlots[0]?.serviceId ?? {};
  const userDetails = userId ?? {};

  return (
    <Wrapper>
      <Card
        bordered={false}
        className="commonCardShadow"
        id="bookingDetailCard"
      >
        {/* Header */}
        <div className="d-flex justify-content-between">
          <img
            src={backIcon}
            width={40}
            height={40}
            onClick={() => {
              navigate(`/seva?page=bookingHistory`);
            }}
            className="back-button-link mt-0"
          />
          <Title level={3} className="d-flex justify-content-center detailTitle">
            Booking Detail
          </Title>
          <div></div>
        </div>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "20px" }}
        >
          <Col>
            <Text strong className="commonTextColor">{moment().format("DD MMM YYYY")}</Text>
          </Col>
          <Col>
            <Tag color={status === "Pending" ? "orange" : "green"}>
              {status}
            </Tag>
          </Col>
        </Row>

        {/* Booking Information Section */}
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Booking ID" className="detailRow">
                <Text strong className="bookingIdColor">{bookingCode}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Booking Date" className="detailRow">
                <Text strong className="commonTextColor">
                  {moment(bookedSlots[0]?.date).format("DD-MMM-YYYY HH:mm")}
                </Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        {/* <Divider /> */}

        {/* User Information Section */}
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="User Email" className="detailRow">
                <Text className="commonTextColor">{userDetails?.email}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Phone Number" className="detailRow">
                <Text className="commonTextColor">{userDetails?.mobileNumber}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Address" className="detailRow">
                <Text className="commonTextColor">{`${userDetails?.addLine1}, ${userDetails?.addLine2}, ${userDetails?.city}, ${userDetails?.state}, ${userDetails?.country}`}</Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        {/* <Divider /> */}

        {/* Service Information Section */}
        {/* <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Service Name">
                <Text strong>{serviceDetails?.name || "N/A"}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Description">
                <Text>
                  {serviceDetails?.description || "No description available"}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Rate">
                <Text strong>₹{serviceDetails?.amount ?? "N/A"}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Frequency">
                <Text>{serviceDetails?.frequency || "N/A"}</Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row> */}

        {/* <Divider /> */}

        {/* Slot Information Section */}
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Slot Date" className="detailRow">
                <Text strong className="commonTextColor">
                  {moment(bookedSlots[0]?.date).format("DD-MMM-YYYY HH:mm")}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Count" className="detailRow">
                <Text className="commonTextColor">{bookedSlots[0]?.count || "N/A"}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Rate" className="detailRow">
                <Text strong className="commonTextColor">₹{bookedSlots[0]?.rate ?? "N/A"}</Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Divider />

        {/* Total Amount */}
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Text strong className="commonTextColor">Total</Text>
              </Col>
              <Col>
                <Text strong className="amountColor">
                  ₹{bookedSlots[0]?.rate * bookedSlots[0]?.count || "N/A"}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
}

export default BookingDetail;
