import { api } from "./api";

export const AuthService = {
  async sendOTP(mobileNumber, countryCode) {
    try {
      const response = await api.post("/auth/check-number", {
        mobileNumber,
        countryCode,
      });
      return response.data;
    } catch (error) {
      console.error("Send OTP Error:", error);
      throw error;
    }
  },

  async verifyOTP(mobileNumber, sOtp) {
    try {
      const otp = Number(sOtp);
      const response = await api.post(
        "/auth/login",
        {
          mobileNumber,
          otp,
          countryCode: "+91",
        },
        {
          headers: {
            platform: "web",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Verify OTP Error:", error);
      throw error;
    }
  },

  async logout() {
    try {
      await api.post("/auth/logout");
      return true;
    } catch (error) {
      console.error("Logout Error:", error);
      throw error;
    }
  },
};
