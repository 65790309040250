import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button, CardText, Col, Form, Row, Spinner } from "reactstrap";
import coverImg from "../assets/pages/Temple.jpeg";
import personImg from "../assets/icons/account.svg";
import emailImg from "../assets/icons/email.svg";
import calendarImg from "../assets/icons/calendar.svg";
import logo from "../assets/logo/main-logo.png";
import "../common.scss";
import { Formik } from "formik";
import CustomCountryMobileField from "../views/common/customCountryMobileField";
import { useNavigate,useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { DatePicker, Input } from "antd";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { loginPage } from "../utility/commonApi";
import { setTrustData } from "../redux/slices/authSlice";
import placeholderImg from "../assets/pages/placeholder.webp";
import * as Yup from "yup"; // Import Yup for validation
const CustomDatePickerComponent =
DatePicker.generatePicker(momentGenerateConfig);

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const hostname = window.location.hostname;
  const dispatch = useDispatch();
  const subdomainChange = process.env.REACT_APP_ADMIN_SUBDOMAIN_REPLACE_URL;
  const adminUrl = process.env.REACT_APP_ADMIN_URL;

  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");

  const handlePhoneChange = ({ countryCode, mobileNumber }) => {
    const sanitizedNumber = mobileNumber.replace(/\D/g, "").trim();
    setMobileNumber(sanitizedNumber);

    setCountryCode(`+${countryCode}`);
  };
  const handleMobileChange = ({ countryCode, mobileNumber }, setFieldValue) => {
    const sanitizedNumber = mobileNumber.replace(/\D/g, "").trim();
    setFieldValue("mobileNumber", sanitizedNumber);
    setCountryCode(`+${countryCode}`);
  };

  const [otp, setOtp] = useState("");
  const onChange = (text) => {
    // console.log("onChange:", text);
    setOtp(text);
  };
  const onInput = (value) => {
    // console.log("onInput:", value);
  };
  const sharedProps = {
    onChange,
    onInput,
  };

  const [step, setStep] = useState("login"); // track current step (login or signup)
  const { sendOTP, verifyOTP, isLoading, registerUser } = useAuth();
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required."),
    mobileNumber: Yup.string().required("Mobile number is required."),
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
  });
  //   const validateMobileNumber = (number) => {
  //     const phoneRegex = /^[6-9]\d{9}$/;
  //     return phoneRegex.test(number);
  //   };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    try {
      const success = await sendOTP(mobileNumber, countryCode);
      if (success) {
        setStep("otp"); // Move to OTP step
        toast.success("OTP sent successfully!");
      }
    } catch (error) {
      toast.error("Failed to send OTP");
    }
  };
  const { from } = location.state || { from: "/" }
  const sevaRecord = location.state?.sevaRecord;

  const handleVerifyOTP = async (e) => {
    e.preventDefault();

    if (otp.length !== 6) {
      toast.error("Please enter a 6-digit OTP");
      return;
    }

    try {
      const success = await verifyOTP(mobileNumber, otp);
      if (success) {
        toast.success("Login successful!");
        navigate(from, { replace: true ,state: sevaRecord});
      }
    } catch (error) {
      toast.error("Failed to verify OTP");
    }
  };

  const handleSignup = async (e, values,resetForm) => {
    e.preventDefault();

    const payload = { ...values,countryCode:countryCode };
    try {
      const success = await registerUser(payload);
      if (success) {
        setStep("login"); // After signup, move to OTP step
        resetForm()
        toast.success("Signup successful! Please verify OTP.");
      } else {
        toast.error("Failed to sign up");
      }
    } catch (error) {
      toast.error("Failed to sign up");
    }
  };

  let subDomainName;
  if (hostname !== adminUrl) {
    subDomainName = hostname.replace(subdomainChange, "");
  } else {
    subDomainName = hostname.replace(
      process.env.REACT_APP_GENERIC_ADMIN_SUBDOMAIN_REPLACE_URL,
      ""
    );
  }
  const loginPageQuery = useQuery({
    queryKey: [subDomainName],
    queryFn: () => loginPage(subDomainName),
  });

  const loginPageData = useMemo(() => {
    dispatch(setTrustData(loginPageQuery?.data?.data?.result));
    return loginPageQuery?.data?.data?.result;
  }, [loginPageQuery]);
  return (
    <div className="loginwrapper auth-wrapper auth-cover">
      <Row className="auth-inner m-0 defaultFontColor">
        <Col
          className="d-none d-lg-flex pe-0 ps-0 align-items-center h-100"
          lg="7"
          sm="12"
        >
          <div className="w-100 h-100 d-lg-flex align-items-center justify-content-center loginBackground">
            <img
              className={`img-fluid w-100 ${
                (loginPageData && loginPageData?.profilePhoto !== "") ||
                loginPageData?.profilePhoto
                  ? "h-100"
                  : ""
              }`}
              src={
                (loginPageData && loginPageData?.profilePhoto !== "") ||
                loginPageData?.profilePhoto
                  ? loginPageData?.profilePhoto
                  : placeholderImg
              }
              alt="Login Cover"
            />
          </div>
        </Col>
        <Col
          className="d-flex justify-content-center align-items-center h-100 auth-bg p-5"
          lg="5"
          sm="12"
          style={{ backgroundColor: "#FFF" }}
        >
          <Col
            className="px-xl-2 mx-auto"
            xs="12"
            sm="8"
            md="8"
            lg="9"
            style={{
              backgroundColor: "#FCF5E7",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              className="logo-container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  maxWidth: "200px",
                  height: "auto",
                  marginLeft: "0",
                  marginRight: "0",
                  marginBottom: "20px",
                }}
              />
            </div>

            <CardText className="signInEnterUserName">
              Ranakpur Jain Temple
            </CardText>

            {step === "login" && (
              <>
                <CardText className="signInEnterMobile">
                  Enter Your Mobile Number
                </CardText>
                <Formik initialValues={{ mobileNumber: "" }}>
                  {(formik) => (
                    <Form className="auth-login-form mt-3 mx-3">
                      <div className="mb-1 d-flex justify-content-center flex-column align-items-center">
                        <div className="mb-5 w-100">
                          <CustomCountryMobileField
                            value={mobileNumber}
                            placeholder="Enter your mobile number"
                            required
                            onChange={handlePhoneChange}
                          />
                        </div>
                        <div className="otpBtn">
                          <Button
                            color="primary"
                            className="addAction-btn"
                            onClick={handleSendOTP}
                            disabled={!mobileNumber || isLoading}
                          >
                            {isLoading ? <Spinner size="sm" /> : "Send OTP"}
                          </Button>
                          <div className="otherOption mt-2">
                            <span>Don't have an account? </span>
                            <span
                              className="signUpText"
                              onClick={() => setStep("signup")}
                            >
                              Sign up.
                            </span>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            )}

            {step === "signup" && (
              <>
                <Formik
                  initialValues={{
                    name: "",
                    mobileNumber: "",
                    email: "",
                    dob: "",
                  }}
                  validationSchema={SignupSchema}
                >
                  {({
                    values,
                    handleBlur,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    isValid,
                    dirty,
                    resetForm
                  }) => (
                    <Form className="auth-login-form mt-3 mx-3">
                      <div className="mb-1 d-flex justify-content-center flex-column align-items-center">
                        <div className="mb-5 w-100">
                          <div className="mb-2">
                            <label>
                              Full Name<span className="text-danger">*</span>
                            </label>
                            <Input
                              type="text"
                              placeholder="Full Name"
                              value={values.name}
                              onChange={(e) =>
                                setFieldValue("name", e.target.value)
                              }
                              onBlur={handleBlur}
                              className="inputBox"
                              name="name"
                              prefix={
                                <img
                                  src={personImg}
                                  alt="person-icon"
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginRight: 8,
                                  }}
                                />
                              }
                            />
                            <div className="errorMessage text-primary">
                              {touched.name && errors.name && (
                                <span className="text-danger">
                                  {errors.name}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="mb-2">
                            <label>
                              Mobile No<span className="text-danger">*</span>
                            </label>
                            <CustomCountryMobileField
                              name="mobileNumber"
                              value={values.mobileNumber}
                              placeholder="Enter your mobile number"
                              onChange={(data) =>
                                handleMobileChange(data, setFieldValue)
                              }
                              onBlur={() =>
                                handleBlur({ target: { name: "mobileNumber" } })
                              }
                              error={errors.mobileNumber}
                              touched={touched.mobileNumber}
                            />
                          </div>
                          <div className="mb-2">
                            <label>
                              Email<span className="text-danger">*</span>
                            </label>
                            <Input
                              type="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={(e) =>
                                setFieldValue("email", e.target.value)
                              }
                              onBlur={handleBlur}
                              className="inputBox"
                              name="email"
                              prefix={
                                <img
                                  src={emailImg}
                                  alt="person-icon"
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginRight: 8,
                                  }}
                                />
                              }
                            />
                            <div className="errorMessage text-primary">
                              {touched.email && errors.email && (
                                <span className="text-danger">
                                  {errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="mb-2 d-flex flex-column">
                            <label>Date of birth</label>
                            <CustomDatePickerComponent
                              value={values.dob}
                              format="DD MMM YYYY"
                              onChange={(date) => setFieldValue("dob", date)}
                              className="inputBox"
                              placeholder="Date of Birth"
                              name="dob"
                              prefix={
                                <img
                                  src={calendarImg} // Use your custom image
                                  alt="calendar-icon"
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginRight: 8,
                                  }}
                                />
                              }
                              disabledDate={(current) =>
                                current &&
                                (current.isSame(moment(), "day") ||
                                  current.isAfter(moment()))
                              }
                            />
                          </div>
                        </div>
                        <div className="otpBtn">
                          <Button
                            color="primary"
                            className="addAction-btn"
                            // disabled={isLoading}
                            disabled={!isValid || !dirty}
                            onClick={(e) => handleSignup(e, values,resetForm)}
                          >
                            {isLoading ? <Spinner size="sm" /> : "Sign Up"}
                          </Button>
                          <div className="otherOption mt-2">
                            <span>Already have an account? </span>
                            <span
                              className="signUpText"
                              onClick={() => setStep("login")}
                            >
                              Login.
                            </span>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            )}

            {step === "otp" && (
              <>
                <CardText className="signInEnterMobile">
                  We have sent OTP on your mobile number
                </CardText>
                <div className="w-100 d-flex justify-content-center align-items-center mb-5">
                  <Input.OTP
                    formatter={(str) => str.toUpperCase()}
                    maxLength={6}
                    value={otp || ""}
                    {...sharedProps}
                    className="form-control"
                    placeholder="Enter OTP"
                  />
                </div>
                <div className="otpBtn">
                  <Button
                    color="primary"
                    className="addAction-btn"
                    onClick={handleVerifyOTP}
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner size="sm" /> : "Sign In"}
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
