import React, { useMemo, useState } from "react";
import {
  Card,
  Row,
  Col,
  Pagination,
  Empty,
  Tag,
  Table,
  Space,
  Button,
  Statistic,
  Tooltip,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { bookingList } from "../utility/commonApi";
import Loader from "../views/common/loader";
import moment from "moment";
import clockImg from "../assets/icons/clockIcon.svg";
import eyeIcon from "../assets/icons/eyeIcon.svg";

function BookingHistory() {
  const navigate = useNavigate();
  const columns = [
    {
      title: "S.No",
      fixed: "left",
      key: "serialNumber",
      width: 50,
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "User Email",
      dataIndex: "userEmail",
      key: "userEmail",
      width: 200,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 180,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      width: 150,
      render: (text) => <span>{moment(text).format("DD MMM YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (status) => (
        <Tag color={status === "Pending" ? "orange" : "green"}>{status}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 80,
      render: (_, record) => (
        <Space size="middle">
          <img
            src={eyeIcon}
            width={25}
            height={25}
            style={{ cursor: "pointer" }}
            type="primary"
            onClick={() => navigate(`/booking/detail/${record.id}`)}
          />
        </Space>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data, isLoading } = useQuery({
    queryKey: ["bookingHistory", currentPage],
    queryFn: () => bookingList({ page: currentPage, limit: pageSize }),
    keepPreviousData: true,
  });

  const bookingData = useMemo(() => {
    return (
      data?.data?.results?.map((booking) => ({
        key: booking.bookingId,
        bookingId: booking.bookingId,
        userEmail: booking.userId?.email,
        serviceName: booking.bookedSlots[0]?.serviceId?.name,
        bookingDate: booking.bookedSlots[0]?.date,
        status: booking.status,
        id: booking._id,
      })) ?? []
    );
  }, [data]);

  const totalBookings = data?.data?.totalResults || 0;

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ flex: 1 }}>
            {bookingData.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <Empty description="No bookings found" />
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div
                  className="d-flex justify-content-end"
                  style={{ marginBottom: "10px" }}
                >
                  <Tag color="orange" className="cardTitle">
                    <span className="">Total Bookings: {totalBookings}</span>
                  </Tag>
                </div>

                <Table
                  className="commonListTable"
                  columns={columns}
                  dataSource={bookingData}
                  scroll={{
                    x: 1500,
                    y: 400,
                  }}
                  sticky={{
                    offsetHeader: 64,
                  }}
                  bordered
                  pagination={false}
                />
                {!isLoading && totalBookings > 0 && (
                  <div className="pagination-container">
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalBookings}
                      onChange={onChangePage}
                      showSizeChanger={false}
                      style={{ marginTop: 16, textAlign: "center" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default BookingHistory;
