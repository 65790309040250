import React, { useEffect, useMemo, useState } from "react";
import { Card, Row, Col, Pagination, Empty, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import he from "he";
import Loader from "../views/common/loader";
import Wrapper from "../views/common/commonWrapper";
import moment from "moment";
import clockImg from "../assets/icons/clockIcon.svg";
import { getPunyarjak } from "../utility/commonApi";
import { fetchImage } from "../utility/commonDownloadS3Image";
import placeholderImage from "../assets/icons/placeholderimage.webp";

function Punyarjak() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  // const startDate = moment().startOf("month").utcOffset(0, true).toISOString();
  // const endDate = moment().endOf("month").utcOffset(0, true).toISOString();
  const { data, isLoading } = useQuery({
    queryKey: ["punyarjak", currentPage],
    queryFn: () =>
      getPunyarjak({ page: currentPage, limit: pageSize, sortOrder: "DESC" }),
    keepPreviousData: true,
  });

  const punyarjakData = useMemo(() => data?.data?.results ?? [], [data]);
  const totalPunyarjaks = data?.data?.totalResults || 0;

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };
  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    if (punyarjakData && punyarjakData.length > 0) {
      const loadImages = async () => {
        const urls = await Promise.all(
          punyarjakData.map(async (item) => {
            const url = await fetchImage(item.imageName);
            return url;
          })
        );
        setImageUrls(urls);
      };

      loadImages();
    }
  }, [punyarjakData]);
  return (
    <Wrapper>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ flex: 1 }}>
            {punyarjakData.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <Empty description="No records found" />
              </div>
            ) : (
              <>
                <div style={{ paddingBottom: "80px" }}>
                  <Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, 16]}>
                    {punyarjakData.map((punyarjak, index) => {
                      const decodedDescription = he.decode(
                        punyarjak.description
                      );
                      const strippedDescription =
                        stripHtmlTags(decodedDescription);

                      const truncatedDescription =
                        strippedDescription.length > 100
                          ? strippedDescription.substring(0, 100) + "..."
                          : strippedDescription;

                      return (
                        <Col
                          xs={24}
                          sm={12}
                          md={8}
                          lg={6}
                          key={punyarjak.id}
                          className="cardColumn"
                        >
                          <Card
                            className="cardContainer"
                            hoverable
                            style={{ width: "100%" }}
                            onClick={() => {
                              const imageSrc = imageUrls && imageUrls[index];

                              navigate(`/detail/punyarjak/${punyarjak.id}`, {
                                state: { imageSrc },
                              });
                            }}
                            cover={
                              <>
                                {imageUrls[index] ? (
                                  <img
                                    className="cardImage"
                                    alt={punyarjak.title}
                                    src={imageUrls[index] || placeholderImage}
                                  />
                                ) : (
                                  <Skeleton.Image active/>
                                )}
                              </>
                            }
                          >
                            <Meta
                              title={
                                <div className="cardTitle">
                                  {punyarjak.title}
                                </div>
                              }
                              description={
                                <div className="cardDescription">
                                  <div>{truncatedDescription}</div>
                                  {strippedDescription.length > 100 && (
                                    <span
                                      className="cardReadMore"
                                      onClick={() => {
                                        const imageSrc =
                                          imageUrls && imageUrls[index];

                                        navigate(
                                          `/detail/punyarjak/${punyarjak.id}`,
                                          {
                                            state: { imageSrc },
                                          }
                                        );
                                      }}
                                    >
                                      {" "}
                                      Read more
                                    </span>
                                  )}
                                </div>
                              }
                            />
                            <div className="cardDate">
                              <img
                                src={clockImg}
                                width={15}
                                height={15}
                                className="me-1"
                              />
                              <span>
                                {`Publish Date: ${moment(
                                  punyarjak.publishDate
                                ).format("DD-MMM-YYYY")}`}
                              </span>
                              <br />
                              {/* You can adjust startTime and endTime if they are present in the data */}
                              {/* <span>{`Time: ${punyarjak.startTime} - ${punyarjak.endTime}`}</span> */}
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>

                {!isLoading && totalPunyarjaks > 0 && (
                  <div className="pagination-container">
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalPunyarjaks}
                      onChange={onChangePage}
                      showSizeChanger={false}
                      style={{ marginTop: 16, textAlign: "center" }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default Punyarjak;
