import { createSlice } from "@reduxjs/toolkit";
const safeParseJSON = (key) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error parsing ${key} from localStorage:`, error);
    return null;
  }
};
const initialState = {
  user: safeParseJSON("user") || null,
  accessToken: localStorage.getItem("accessToken") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
  isAuthenticated: !!localStorage.getItem("accessToken"),
  trustData: safeParseJSON("trustData") || null,
  trustDetail:{}
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokens: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = true;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    },
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setTrustData: (state, action) => {
      state.trustData = action.payload;
      localStorage.setItem("trustData", JSON.stringify(action.payload));
    },
    logOut: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      localStorage.clear();
    },
    handleTrustDetail(state, action) {
      // state.trustDetail = action.payload;
    },
  },
});

export const { setTokens, setUser, logOut, handleTrustDetail, setTrustData } =
  authSlice.actions;
export const selectAccessToken = (state) => state.auth.tokens.accessToken;
export const selectRefreshToken = (state) => state.auth.tokens.refreshToken;
export default authSlice.reducer;
