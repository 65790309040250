import React, { useMemo } from "react";
import { Row, Col } from "reactstrap"; // Import Spinner from Reactstrap
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../common.scss";
import {
  getEventsbyId,
  getNewsDetail,
  getNoticeDetail,
  getPunyarjakDetail,
} from "../../utility/commonApi";
import { useQuery } from "@tanstack/react-query";
import he from "he";
import Loader from "./loader";
import Wrapper from "./commonWrapper";
import backIcon from "../../assets/icons/back.svg";
import placeholderImage from '../../assets/icons/placeholderimage.webp'
function CommonDetails() {
  const location = useLocation();
  const { imageSrc } = location.state || {};
  const navigate = useNavigate();
  const { id, type } = useParams(); // Capture both id and type from the URL

  // Determine the API function based on the type
  // Fetching news details using React Query
  const { data, isLoading } = useQuery({
    queryKey: [type + "Detail", id],
    queryFn: () => {
      // Conditionally call the correct API function based on type
      if (type === "news") {
        return getNewsDetail({ newsId: id }); // Pass newsId when type is 'news'
      } else if (type === "notice") {
        return getNoticeDetail({ noticeId: id }); // Pass noticeId when type is 'notice'
      } else if (type === "punyarjak") {
        return getPunyarjakDetail({
          punyarjakId: id,
          languageId: "6332cbba8054b2cac94da3d1",
        }); // Pass noticeId when type is 'notice'
      } else if (type === "event") {
        return getEventsbyId(id); // Pass noticeId when type is 'notice'
      }
      // return Promise.reject("Invalid type"); // Handle invalid type if needed
    },
    keepPreviousData: true,
  });

  // Memoizing the fetched data
  const newsData = useMemo(() => data?.data?.result ?? {}, [data]);

  // console.log(newsData); // Debugging log for data

  return (
    <>
      <Wrapper>
        {/* Back Button */}
        <div className="back-button">
          <img
            src={backIcon}
            width={40}
            height={40}
            onClick={() => navigate(-1)}
            className="back-button-link"
          />
        </div>

        {/* Loader while data is loading */}
        {isLoading ? (
          <Loader />
        ) : (
          /* Two Column Layout */
          <Row>
            {/* First Column: Image */}
            <Col md={6} sm={12}>
              <img
                src={imageSrc || placeholderImage}
                alt={newsData?.title || "News Image"}
                className="image-column"
              />
            </Col>

            {/* Second Column: Title and Description */}
            <Col md={6} sm={12} className="text-column">
              <h2 className="title">
                {newsData?.title || "No Title Available"}
              </h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: newsData?.body
                    ? he.decode(newsData.body)
                    : "No Description Available",
                }}
              />
            </Col>
          </Row>
        )}
      </Wrapper>
    </>
  );
}

export default CommonDetails;
