import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_PUBLIC;

export const downloadFile = async (filepath, params) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}storage/download/${filepath}`,
      {
        responseType: "blob",
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};
export const fetchImage = async (imageUrl, i) => {
  if (imageUrl) {
    try {
      const imgBlob = await downloadFile(imageUrl);
      const imgURL = URL.createObjectURL(imgBlob);
      return imgURL;
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }
};
