import React, { useEffect, useState } from "react";
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap";
import "../common.scss";
import he from "he";
import { stripHtmlTags } from "../utility/commonDecodeHtmlBody";
import { useLocation, useNavigate } from "react-router-dom";
import { Field, Formik } from "formik";
import { Card, Select } from "antd";
import moment from "moment";
import { createBooking } from "../utility/commonApi";
import Swal from "sweetalert2";
import backIcon from "../assets/icons/back.svg";
import { useQueryClient } from "@tanstack/react-query";
import kalashIcon from "../assets/icons/kalash.png";

function SevaBooking({ setSevaList, setBookingSeva, selectedSeva }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedSevaData, setSelectedSevaData] = useState(
    selectedSeva || null
  );

  useEffect(() => {
    setSelectedSevaData(location.state);
  }, [location.state]);

  const handleBack = () => {
    navigate(`/seva?page=sevaList`);
    setSevaList(true);
    setBookingSeva(false);
  };

  const decodedDescription = selectedSevaData
    ? he.decode(selectedSevaData.description)
    : "";

  const description = stripHtmlTags(decodedDescription);

  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  // Handle date selection
  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  useEffect(() => {
    if (selectedSevaData && selectedSevaData.dates) {
      setAvailableDates(selectedSevaData.dates); // Set the available dates
      setSelectedDates(selectedSevaData.dates); // Prefill selected dates
    }
  }, [selectedSevaData]);

  const CustomDateSelectComponent = ({ dates, value, onChange, ...props }) => {
    return (
      <Select
        {...props}
        value={value}
        onChange={onChange}
        mode="multiple"
        placeholder="Select Dates"
        style={{ width: "100%", height: "auto" }}
      >
        {dates.map((date, index) => (
          <Select.Option key={index} value={date}>
            {moment(date).format("DD MMM YYYY")}
          </Select.Option>
        ))}
      </Select>
    );
  };
  const queryClient = useQueryClient();
  return (
    <>
      <div className="back-button mb-0 mt-0">
        <img
          onClick={handleBack}
          className="back-button-link"
          src={backIcon}
          width={40}
          height={40}
        />
      </div>

      {/* Desktop View */}
      <Card className="desktopView commonCardShadow">
        <Row>
          {/* First Column: Image, Title, and Description */}
          <Col md={4} sm={12}>
            <Row>
              <Col xs={12}>
                <img
                  src={
                    selectedSevaData && selectedSevaData.images?.length > 0
                      ? selectedSevaData.images[0]?.presignedUrl
                      : kalashIcon
                  }
                  alt={
                    selectedSevaData
                      ? selectedSevaData.name
                      : "" || "Seva Image"
                  }
                  className="image-column sevaBookingImage"
                />
              </Col>
              <Col xs={12} className="mt-3">
                <h2 className="title">
                  {selectedSevaData
                    ? selectedSevaData.name
                    : "" || "No Title Available"}
                </h2>
              </Col>
              <Col xs={12} className="mt-2">
                <div className="description">{description}</div>
              </Col>
            </Row>
          </Col>

          {/* Second Column: Booking Form */}
          <Col md={8} sm={12}>
            <h3 className="booking-title">Book Your Seva</h3>
            <Formik
              initialValues={{
                count: 1,
                amount: selectedSevaData ? selectedSevaData.amount : "",
                selectedDates: [],
              }}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting }) => {
                const payload = {
                  serviceId: selectedSevaData ? selectedSevaData._id : "",
                  count: values.count,
                  dates: values.selectedDates,
                };

                try {
                  const response = await createBooking(payload);
                  if (response?.code === 201 && response?.status === true) {
                    Swal.fire({
                      title: "Booking Successful!",
                      text: `Your booking ID is ${response.data.result.bookingId}`,
                      icon: "success",
                    }).then(() => {
                      queryClient.invalidateQueries(["Masters"]);
                      navigate("/seva?page=bookingHistory");
                    });
                  } else {
                    throw new Error(
                      response?.message ||
                        "Something went wrong. Please try again."
                    );
                  }
                } catch (error) {
                  Swal.fire({
                    title: "Booking Failed",
                    text:
                      error?.message ||
                      "An unexpected error occurred. Please try again.",
                    icon: "error",
                    confirmButtonText: "Close",
                  });
                  console.error("Error creating booking:", error);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <Row form>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="count">Count</Label>
                        <Field
                          name="count"
                          type="number"
                          className="form-control"
                          min="1"
                          value={values.count}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="dates">Available Dates</Label>
                        <CustomDateSelectComponent
                          dates={availableDates}
                          value={values.selectedDates}
                          onChange={(dates) =>
                            setFieldValue("selectedDates", dates)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="amount">Amount</Label>
                        <Field
                          name="amount"
                          value={values.amount}
                          type="number"
                          className="form-control"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Booking..." : "Book Now"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Card>

      {/* Mobile View */}
      <div className="mobileView">
        <Card className="commonCardShadow">
          <Row>
            <Col xs={12} sm={4} className="text-center mb-3">
              <img
                src={
                  selectedSevaData && selectedSevaData.images?.length > 0
                    ? selectedSevaData.images[0]?.presignedUrl
                    : kalashIcon
                }
                alt={selectedSevaData ? selectedSevaData.name : "Seva Image"}
                className="sevaBookingImage"
              />
            </Col>

            {/* Column for Title and Description */}
            <Col xs={12} sm={8} className="text-center mb-3">
              <h5 className="commonTextColor commonFont">
                {selectedSevaData ? selectedSevaData.name : "Service Name"}
              </h5>
              <p className="commonTextColor commonFont">{description}</p>
            </Col>

            <Col xs={12} className="form-container">
              <Formik
                initialValues={{
                  count: 1,
                  amount: selectedSevaData ? selectedSevaData.amount : "",
                  selectedDates: [],
                }}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting }) => {
                  const payload = {
                    serviceId: selectedSevaData ? selectedSevaData._id : "",
                    count: values.count,
                    dates: values.selectedDates,
                  };

                  try {
                    const response = await createBooking(payload);
                    if (response?.code === 201 && response?.status === true) {
                      Swal.fire({
                        title: "Booking Successful!",
                        text: `Your booking ID is ${response.data.result.bookingId}`,
                        icon: "success",
                      }).then(() => {
                        queryClient.invalidateQueries(["Masters"]);
                        navigate("/seva?page=bookingHistory");
                      });
                    } else {
                      throw new Error(
                        response?.message ||
                          "Something went wrong. Please try again."
                      );
                    }
                  } catch (error) {
                    Swal.fire({
                      title: "Booking Failed",
                      text:
                        error?.message ||
                        "An unexpected error occurred. Please try again.",
                      icon: "error",
                      confirmButtonText: "Close",
                    });
                    console.error("Error creating booking:", error);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="count">Count</Label>
                      <Field
                        name="count"
                        type="number"
                        className="form-control"
                        min="1"
                        value={values.count}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="dates">Available Dates</Label>
                      <CustomDateSelectComponent
                        dates={availableDates}
                        value={values.selectedDates}
                        onChange={(dates) =>
                          setFieldValue("selectedDates", dates)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="amount">Amount</Label>
                      <Field
                        name="amount"
                        value={values.amount}
                        type="number"
                        className="form-control"
                        readOnly
                      />
                    </FormGroup>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Booking..." : "Book Now"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
}

export default SevaBooking;
