import React, { useEffect, useMemo, useState } from "react";
import { Card, Row, Col, Pagination, Empty, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getEvents } from "../utility/commonApi";
import he from "he";
import Loader from "../views/common/loader";
import Wrapper from "../views/common/commonWrapper";
import moment from "moment";
import clockImg from "../assets/icons/clockIcon.svg";
import { fetchImage } from "../utility/commonDownloadS3Image";
import placeholderImage from "../assets/icons/placeholderimage.webp";
function Event() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  // const startDate = moment().startOf("month").utcOffset(0, true).toISOString();
  // const endDate = moment().endOf("month").utcOffset(0, true).toISOString();
  // Fetching events details using React Query
  const { data, isLoading } = useQuery({
    queryKey: ["events", currentPage],
    queryFn: () =>
      getEvents({ page: currentPage, limit: pageSize, sortOrder: "DESC" }),
    keepPreviousData: true,
  });

  // Memoizing the fetched data
  const eventData = useMemo(() => data?.data?.results ?? [], [data]);
  const totalEvents = data?.data?.totalResults || 0;

  // Handle page change
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  // Function to decode HTML entities in body (if needed)
  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };
  const [imageUrls, setImageUrls] = useState({});
  useEffect(() => {
    if (eventData && eventData.length > 0) {
      const loadImages = async () => {
        const urls = {};
        await Promise.all(
          eventData.map((event) => {
            if (event.images && event.images.length > 0) {
              return Promise.all(
                event.images.map(async (image) => {
                  const url = await fetchImage(image.name);
                  urls[event.id] = urls[event.id] || [];
                  urls[event.id].push(url);
                })
              );
            }
          })
        );
        setImageUrls(urls);
      };

      loadImages();
    }
  }, [eventData]);

  return (
    <>
      <Wrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Loader - Display while data is loading */}
          {isLoading ? (
            <Loader />
          ) : (
            <div style={{ flex: 1 }}>
              {/* If there are no events, show "No records found" */}
              {eventData.length === 0 ? (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <Empty description="No records found" />
                </div>
              ) : (
                <>
                  {/* Displaying events in grid */}
                  <div style={{ paddingBottom: "80px" }}>
                    <Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, 16]}>
                      {eventData.map((event) => {
                        const decodedBody = he.decode(event.body);
                        const strippedBody = stripHtmlTags(decodedBody);

                        // Truncate text if it's too long
                        const truncatedBody =
                          strippedBody.length > 100
                            ? strippedBody.substring(0, 100) + "..."
                            : strippedBody;

                        return (
                          <Col
                            xs={24}
                            sm={12}
                            md={8}
                            lg={6}
                            key={event.id}
                            className="cardColumn"
                          >
                            <Card
                              className="cardContainer"
                              hoverable
                              style={{ width: "100%" }}
                              onClick={() => {
                                const imageSrc =
                                  imageUrls[event.id] && imageUrls[event.id][0];

                                navigate(`/detail/event/${event.id}`, {
                                  state: { imageSrc },
                                });
                              }}
                              cover={
                                <>
                                  {imageUrls && imageUrls[event.id] ? (
                                    <img
                                      className="cardImage"
                                      alt={event.title}
                                      src={
                                        (imageUrls[event.id] &&
                                          imageUrls[event.id][0]) ||
                                        placeholderImage
                                      }
                                    />
                                  ) : (
                                    <Skeleton.Image active />
                                  )}
                                </>
                              }
                            >
                              <Meta
                                title={
                                  <div className="cardTitle">{event.title}</div>
                                }
                                description={
                                  <div className="cardDescription">
                                    <div>{truncatedBody}</div>
                                    {strippedBody.length > 100 && (
                                      <span
                                        className="cardReadMore"
                                        onClick={() => {
                                          const imageSrc =
                                            imageUrls[event.id] &&
                                            imageUrls[event.id][0];

                                          navigate(
                                            `/detail/event/${event.id}`,
                                            {
                                              state: { imageSrc },
                                            }
                                          );
                                        }}
                                      >
                                        {" "}
                                        Read more
                                      </span>
                                    )}
                                  </div>
                                }
                              />
                              <div className="cardDate">
                                <img
                                  src={clockImg}
                                  width={15}
                                  height={15}
                                  className="me-1"
                                />
                                <span>
                                  {`Publish Date: ${moment(
                                    event.startDate
                                  ).format("DD-MMM-YYYY")}`}
                                </span>
                                <br />
                                <span>{`Time: ${event.startTime} - ${event.endTime}`}</span>
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>

                  {/* Pagination */}
                  {!isLoading && totalEvents > 0 && (
                    <div className="pagination-container">
                      <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalEvents}
                        onChange={onChangePage}
                        showSizeChanger={false}
                        style={{ marginTop: 16, textAlign: "center" }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Wrapper>
    </>
  );
}

export default Event;
