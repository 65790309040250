import React from "react";
import "../App.css";
import CommonTabs from "../views/common/commonTabs";
import Wrapper from "../views/common/commonWrapper";
function Updates() {
  return (
    <>
      <Wrapper>
        <CommonTabs />
      </Wrapper>
    </>
  );
}

export default Updates;
