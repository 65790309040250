import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav as ReactstrapNav,
  NavItem,
  NavLink,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import logo from "../assets/logo/main-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa"; // For Hamburger Icon
import "../common.scss";
import { useDispatch, useSelector } from "react-redux";
import { logOut, setTrustData } from "../redux/slices/authSlice";
import { useQuery } from "@tanstack/react-query";
import { loginPage } from "../utility/commonApi";

const Nav = () => {
  const subdomainChange = process.env.REACT_APP_ADMIN_SUBDOMAIN_REPLACE_URL;
  const adminUrl = process.env.REACT_APP_ADMIN_URL;
  const hostname = window.location.hostname;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loggedOut, setLoggedOut] = useState(false);
  const [user, setUser] = useState(null);
  const location = useLocation(); // Get the current route
  const userDetails = useSelector((state) => state.auth);
  const donationViaApp = userDetails && userDetails?.trustData?.donationViaApp;
  const isSevaEnabled = userDetails && userDetails?.trustData?.isSevaEnabled;
  const handleLogout = () => {
    // setLoggedOut(true);
    navigate("/");
    dispatch(logOut());
    if (window.location.pathname === "/") {
      window.location.reload();
    }
  };
  // const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    // Fetching user data from localStorage
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Check if it's mobile
  const [activeLink, setActiveLink] = useState(location.pathname); // Track active link

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);
  useEffect(() => {
    const hash = location.hash; // Extract hash from the URL
    if (hash) {
      const element = document.querySelector(hash); // Select the target element
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);
  // Toggle Sidebar
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => activeLink === path;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsOpen(false); // Close the sidebar on desktop
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle active link change
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  let subDomainName;
  if (hostname !== adminUrl) {
    subDomainName = hostname.replace(subdomainChange, "");
  } else {
    subDomainName = hostname.replace(
      process.env.REACT_APP_GENERIC_ADMIN_SUBDOMAIN_REPLACE_URL,
      ""
    );
  }
  const loginPageQuery = useQuery({
    queryKey: [subDomainName],
    queryFn: () => loginPage(subDomainName),
  });

  const loginPageData = useMemo(() => {
    dispatch(setTrustData(loginPageQuery?.data?.data?.result));
    return loginPageQuery?.data?.data?.result;
  }, [loginPageQuery]);
  return (
    <div>
      <Navbar>
        <Container className="d-flex justify-content-between">
          {/* Logo */}
          <NavbarBrand href="/">
            <img
              alt="logo"
              src={logo}
              style={{
                height: 60,
                objectFit: "contain",
                width: 170,
              }}
            />
          </NavbarBrand>

          {/* Normal Menu Hidden on Mobile */}
          <ReactstrapNav className="d-none d-md-flex align-items-center">
            <NavItem>
              <NavLink
                tag={Link}
                to="/"
                className={isActive("/") ? "active" : ""}
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/#about"
                className={isActive("/#about") ? "active" : ""}
              >
                About Us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/updates"
                className={isActive("/updates") ? "active" : ""}
              >
                Updates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/events"
                className={isActive("/events") ? "active" : ""}
              >
                Events
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/notices"
                className={isActive("/notices") ? "active" : ""}
              >
                Notices
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/#facilities"
                className={isActive("/#facilities") ? "active" : ""}
              >
                Facilities
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/#galleries"
                className={isActive("/#galleries") ? "active" : ""}
              >
                Gallery
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/punyarjak"
                className={isActive("/punyarjak") ? "active" : ""}
              >
                Punyarjak
              </NavLink>
            </NavItem>
            {isSevaEnabled && (
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/seva"
                  className={isActive("/seva") ? "active" : ""}
                  state={{ from: "/seva" }}
                >
                  Seva
                </NavLink>
              </NavItem>
            )}
            {/* <NavItem>
              <NavLink
                tag={Link}
                to="/seva"
                className={isActive("/seva") ? "active" : ""}
              >
                Seva
              </NavLink>
            </NavItem> */}
          </ReactstrapNav>

          {/* Donate Now Button */}
          <div className="d-flex">
            {/* {isSevaEnabled !== "false" && (
              <div className="d-none d-md-flex align-items-center me-1">
                <Button
                  color="primary"
                  tag={Link}
                  to="/login"
                  state={{ from: "/seva" }}
                >
                  Seva
                </Button>
              </div>
            )} */}
            {!donationViaApp && (
              <div className="d-none d-md-flex align-items-center me-1">
                <Button
                  color="primary"
                  tag={Link}
                  to="/login"
                  state={{ from: "/" }}
                >
                  Donate Now
                </Button>
              </div>
            )}
            <div className="d-none d-md-flex align-items-center">
              {user ? (
                <Button color="primary" onClick={handleLogout}>
                  Logout
                </Button>
              ) : (
                isSevaEnabled &&
                !donationViaApp && (
                  <Button
                    color="primary"
                    tag={Link}
                    to="/login"
                    state={{ from: "/seva" }}
                  >
                    Login
                  </Button>
                )
              )}
            </div>
          </div>

          {/* Hamburger Menu Visible on Mobile */}
          <div className="d-md-none d-flex align-items-center justify-content-center">
            <FaBars
              size={24}
              style={{ cursor: "pointer" }}
              onClick={toggleSidebar}
            />
          </div>
        </Container>
      </Navbar>

      {/* Sidebar Drawer Menu */}
      <Offcanvas
        isOpen={isOpen}
        toggle={toggleSidebar}
        direction="end"
        style={{ width: "335px" }}
        className="d-md-none"
      >
        <OffcanvasHeader toggle={toggleSidebar}>
          <img
            alt="logo"
            src={logo}
            style={{
              height: 50,
              objectFit: "contain",
              width: 150,
            }}
          />
        </OffcanvasHeader>
        <OffcanvasBody>
          <ReactstrapNav className="flex-column">
            {/* Menu Items */}
            <NavItem>
              <NavLink
                tag={Link}
                to="/"
                onClick={() => {
                  setActiveLink("/");
                  toggleSidebar();
                }}
                className={`menu-item ${isActive("/") ? "active" : ""}`}
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/#about"
                onClick={() => {
                  setActiveLink("/#about");
                  toggleSidebar();
                }}
                className={`menu-item ${isActive("/#about") ? "active" : ""}`}
              >
                About Us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/updates"
                onClick={() => {
                  setActiveLink("/updates");
                  toggleSidebar();
                }}
                className={`menu-item ${isActive("/updates") ? "active" : ""}`}
              >
                Updates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/events"
                onClick={() => {
                  setActiveLink("/events");
                  toggleSidebar();
                }}
                className={`menu-item ${isActive("/events") ? "active" : ""}`}
              >
                Events
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/notices"
                onClick={() => {
                  setActiveLink("/notices");
                  toggleSidebar();
                }}
                className={`menu-item ${isActive("/notices") ? "active" : ""}`}
              >
                Notices
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/#facilities"
                onClick={() => {
                  setActiveLink("/#facilities");
                  toggleSidebar();
                }}
                className={`menu-item ${
                  isActive("/#facilities") ? "active" : ""
                }`}
              >
                Facilities
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/#galleries"
                onClick={() => {
                  setActiveLink("/#galleries");
                  toggleSidebar();
                }}
                className={`menu-item ${
                  isActive("/#galleries") ? "active" : ""
                }`}
              >
                Gallery
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/punyarjak"
                onClick={() => {
                  setActiveLink("/punyarjak");
                  toggleSidebar();
                }}
                className={`menu-item ${
                  isActive("/punyarjak") ? "active" : ""
                }`}
              >
                Punyarjak
              </NavLink>
            </NavItem>
            {isSevaEnabled && (
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/seva"
                  onClick={() => {
                    setActiveLink("/seva");
                    toggleSidebar();
                  }}
                  className={`menu-item ${isActive("/seva") ? "active" : ""}`}
                >
                  Seva
                </NavLink>
              </NavItem>
            )}
            {/* <NavItem>
              <NavLink
                tag={Link}
                to="/seva"
                onClick={() => {
                  setActiveLink("/seva");
                  toggleSidebar();
                }}
                className={`menu-item ${isActive("/seva") ? "active" : ""}`}
              >
                Seva
              </NavLink>
            </NavItem> */}
          </ReactstrapNav>
          {/* {isSevaEnabled !== "false" && (
            <div className="mt-3">
              <Button
                color="primary"
                tag={Link}
                to="/login"
                state={{ from: "/seva" }}
                block
              >
                Seva
              </Button>
            </div>
          )} */}
          {!donationViaApp && (
            <div className="mt-2">
              <Button
                color="primary"
                tag={Link}
                to="/login"
                state={{ from: "/" }}
                block
              >
                Donate Now
              </Button>
            </div>
          )}
          <div className="mt-2">
            {user ? (
              <Button color="primary" onClick={handleLogout} block>
                Logout
              </Button>
            ) : (
              (isSevaEnabled &&
              !donationViaApp) && (
                <Button
                  color="primary"
                  tag={Link}
                  to="/login"
                  state={{ from: "/seva" }}
                  block
                >
                  Login
                </Button>
              )
            )}
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default Nav;
