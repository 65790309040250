import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { getDetails } from "../apis/getDetails";
import ErrorImage from "../assets/icons/error.svg";

const Error = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);
  const [tenantExists, setTenantExists] = useState(false);

  const extractTenantFromSubdomain = () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];
    return subdomain || null;
  };

  const tenantName = extractTenantFromSubdomain();

  useEffect(() => {
    if (!tenantName) {
      setTenantExists(false);
      return;
    }

    const checkTenant = async () => {
      try {
        const response = await getDetails(tenantName);
        console.log(response);
        if (response && response.data) {
          setTenantExists(true);
        }
      } catch (error) {
        setTenantExists(false);
      }
    };
    checkTenant();
  }, [tenantName]);

  useEffect(() => {
    if (tenantExists === false) {
      return;
    }

    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [navigate, tenantExists]);

  return (
    <>
      <Helmet>
        <title>Page Not Found - Apna Dharm</title>
        <meta name="description" content="The requested page was not found." />
      </Helmet>
      <div className="misc-wrapper">
        <div className="misc-inner p-2 p-sm-3">
          <div className="w-100 text-center">
            <h5
              className="mb-1"
              style={{ fontSize: "5rem", fontWeight: "bold", color: "#FF8744" }}
            >
              404
            </h5>
            <h2 className="mb-1">Page Not Found 🕵🏻‍♀️</h2>
            <p className="mb-2">
              Oops! 😖 The requested URL was not found on this server.
            </p>
            {tenantExists === false ? (
              <p className="mb-2" style={{ color: "red" }}>
                Page for "{tenantName}" does not exist. Please check the URL.
              </p>
            ) : (
              <p className="mb-2">
                Redirecting to the home page in <strong>{countdown}</strong>{" "}
                seconds
              </p>
            )}
            <div style={{ position: "relative", display: "inline-block" }}>
              {tenantExists !== false && (
                <Button
                  tag={Link}
                  to="/"
                  color="primary"
                  className="btn-sm-block mb-2"
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                  }}
                >
                  Back to Home
                </Button>
              )}
              <img
                className="img-fluid"
                src={ErrorImage}
                alt="Page not found"
                onError={(e) => (e.target.src = ErrorImage)}
                style={{ display: "block" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
