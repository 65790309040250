import { callApi } from "../utils/apiUtils";
import { API_AUTH_URL } from "../utils/authApiInstance";
const trustId = localStorage.getItem("trustId");
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL_PUBLIC;
const API_BASE_URL_ADMIN = process.env.REACT_APP_API_BASE_URL;
export const loginPage = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.get(`${API_AUTH_URL}public/sub-domain-details/${payload}`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });

export const getAllNews = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/news`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getNewsTags = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/news/tags`, {
        ...payload,
        englishLanguageId: "6332cbba8054b2cac94da3d1",
        languageId: "",
      }),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getNewsDetail = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/news/get-news`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getEvents = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/event`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getEventsbyId = (eventId) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/event/get-event`, {
        eventId: eventId,
      }),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getNotices = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/notice`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getNoticeDetail = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/notice/get-notice`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getPunyarjak = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/punyarjak`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getPunyarjakDetail = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/punyarjak/get`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const getAllSeva = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL}${trustId}/service`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const createBooking = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_ADMIN}${trustId}/booking/create`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const bookingList = (payload) =>
  callApi({
    requestFunction: (axios) =>
      axios.post(`${API_BASE_URL_ADMIN}${trustId}/booking/list`, payload),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
export const bookingDetail = (bookingId) =>
  callApi({
    requestFunction: (axios) =>
      axios.get(`${API_BASE_URL_ADMIN}${trustId}/booking/${bookingId}`),
    showToastOnSuccess: false,
    showToastOnError: false,
  });
