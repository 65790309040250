import { useState } from "react";
import { useDispatch } from "react-redux";
import { AuthService } from "../services/authService";
import { setTokens, logOut, setUser } from "../redux/slices/authSlice";
import { toast } from "react-toastify";
import { callApi } from "../utils/apiUtils";
const API_BASE_URL_APP = process.env.REACT_APP_API_BASE_URL_APP;

export const useAuth = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const sendOTP = async (mobileNumber, countryCode) => {
    try {
      setIsLoading(true);
      const response = await AuthService.sendOTP(mobileNumber, countryCode);
      // console.log("OTP Send Response:", response); // Debug log
      return true;
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to send OTP");
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async (mobileNumber, otp) => {
    try {
      setIsLoading(true);
      const response = await AuthService.verifyOTP(mobileNumber, otp);

      if (response.status && response.data) {
        const { result, tokens } = response.data;
        // Set tokens first
        dispatch(
          setTokens({
            accessToken: tokens.access.token,
            refreshToken: tokens.refresh.token,
          })
        );

        // Then set user data
        dispatch(setUser(result));

        return true;
      }
      return false;
    } catch (error) {
      console.error("Verify OTP Error:", error);
      toast.error(error.response?.data?.message || "Invalid OTP");
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      await AuthService.logout();
      dispatch(logOut());
      toast.success("Logged out successfully");
    } catch (error) {
      toast.error("Logout failed");
    }
  };
  const registerUser = (payload) =>
    callApi({
      requestFunction: (axios) =>
        axios.post(`${API_BASE_URL_APP}auth/register`, payload),
      showToastOnSuccess: false,
      showToastOnError: false,
    });

  return {
    sendOTP,
    verifyOTP,
    logout,
    isLoading,
    registerUser
  };
};
